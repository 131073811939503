/* eslint camelcase: "off" */

import { jwtDecode } from 'jwt-decode';

function getCookiesMap(cookiesString) {
  if (!cookiesString) {
    return {};
  }

  return cookiesString.split(';')
    .map(cookieString => cookieString.trim().split('='))
    .reduce((acc, curr) => {
      const [name, value] = curr;
      acc[name] = value;
      return acc;
    }, {});
}

function getToken(cookie) {
  const token = getCookiesMap(cookie).jwt_token;
  if (token) {
    return token;
  }
  if (process.env.REACT_APP_TOKEN) {
    return process.env.REACT_APP_TOKEN;
  }
  return null;
}

function roleMatches(claim, expectedRole) {
  if (!claim || !claim.r) {
    return false;
  }
  return claim.r === expectedRole;
}

function isAbleToUseSupervisor(token) {
  let isAbleToUse = false;
  if (token && token.grants) {
    ['supervisor', 'projectAdmin'].forEach((role) => {
      const isPresent = !!(token.grants.cs || []).find(claim => roleMatches(claim, role));
      if (isPresent) {
        isAbleToUse = true;
      }
    });
  }
  return isAbleToUse;
}

function tokenDataFromString(rawToken) {
  if (!rawToken) {
    return {
      raw: '',
      grants: {},
    };
  }

  try {
    const grants = jwtDecode(rawToken);
    return {
      raw: rawToken,
      grants,
    };
  } catch (e) {
    return {
      raw: '',
      grants: {},
    };
  }
}

function decodeToken(cookie) {
  return tokenDataFromString(getToken(cookie));
}

export { decodeToken, tokenDataFromString, isAbleToUseSupervisor };
